.background-img{
    width: 100%;
    height: 600px;
}
@media (max-width: 600px) {
    .background-img{
        height: 12rem; 
        width: 100%;
       padding-top:10px
    }
    
}
@media (max-width: 600px) {
  .responsive-desgin{
display:none;

  }
  
}
/* Product car Toggle */

/* photoshop */
@media only screen and (max-width:768px) {
  .responsive-col {
  padding-top: 10px /* 96px */;
  padding-bottom: 10px /* 96px */;
  }
}
/* Toggle */
@media only screen and (max-width:768px) {
  .responsive-toggle-btn{
 margin-left:10px;
 margin-top:10px;
 z-index: 30px;

 


  }
}
@media only screen and (max-width:1280px) {
    .responsive-toggle-btn{
 
   margin-top:10px;
    }
  }
 
  /* sidebar */

  .before,
  .after {
    margin: 0;
  }

  .before figcaption,
  .after figcaption {
    background: #fff;
    border: 1px solid #c0c0c0;
 
    color: #2e3452;
    opacity: 0.8;
    padding: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 100%;
  }

  .before figcaption {
    left: 12px;
  }

  .after figcaption {
    right: 12px;
  }
  @media only screen and (max-width:768px) {
    .before figcaption,
  .after figcaption{
  /* background:none;
  border:none */
  padding: 2px;
  font-size: 7px;
  
    }
  }
  
  .slick-slide > div{
    margin: 0 10px;
  }
  @media only screen and (max-width:428px) {
   
  .resposive-img {
  /* background:none;
  border:none */
 background-color: red;
  width: 200px;
    }
  }
  @media only screen and (max-width: 426px) {
    /* For tablets: */
    .mobile-desktop  {
    
     display: grid;
  grid-template-columns: repeat(a, minmax(270px, 2fr));
    }
    
  }